import React from "react";
import { DELETE } from "../../helpers/requests";
import { Button, Modal } from "react-bootstrap";
import { getLanguage, getUser } from "../../helpers/auth";
import I18n from "../../Config/I18n";

const body = getUser();
const l = getLanguage();
export default function CancelSubscription({ open, handleClose, handleOpenSnackbar, setMessageSnackBar, setAlertVariant }) {
  // const cancelSubscription = () => {
  //   DELETE("/payment", body).then(response => {
  //     if (response.error) {
  //       setMessageSnackBar("Problem to unsubscribe! Contact the founders please.");
  //       setAlertVariant("danger");
  //       handleOpenSnackbar();
  //       return console.error(response.error.message);
  //     }
  //   });
  //   setMessageSnackBar("You are well unsubscribed !");
  //   setAlertVariant("success");
  //   handleOpenSnackbar();
  //   handleClose();
  //   //Refresh the page to display correct info (unsubscribe button -> subscribe button + get full access in the menu)
  //   window.location.reload(false);
  // };

  const cancelSubscription = async () => {
    console.log('API URL:', process.env.REACT_APP_API_URL);    
    try {
      const response = await DELETE("/payment", body);
      if (response.error) {
        setMessageSnackBar("Problem to unsubscribe! Contact the founders please.");
        setAlertVariant("danger");
        handleOpenSnackbar();
        console.error(response.error.message);
        return;
      }
      setMessageSnackBar("You are well unsubscribed!");
      setAlertVariant("success");
      handleOpenSnackbar();
      handleClose();
      //Refresh the page to display correct info (unsubscribe button -> subscribe button + get full access in the menu)
      window.location.reload(false);
    } catch (error) {
      setMessageSnackBar("Failed to cancel subscription. Please try again later.");
      setAlertVariant("danger");
      handleOpenSnackbar();
      console.error(error);
    }
  };


  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t("account.manageSubscription", { locale: l })} </Modal.Title>
      </Modal.Header>
      <Modal.Body>{I18n.t("account.manageSubscriptionMessage", { locale: l })}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={cancelSubscription}>
          {I18n.t("account.cancelSubscription", { locale: l })}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {I18n.t("close", { locale: l })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
